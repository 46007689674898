import * as React from 'react';
import withWSMessageReceiver, {
  WSMessageReceiverComponent,
} from '../../../utils/withWSMessageReceiver';
import VoteParticipant from '../vote/voteParticipant';
import { VoteAggregatedResults } from '../vote/voteScores';

type PersistedData = {
  selectedScore: number;
  comment: string;
  submitted: boolean;
  results: VoteAggregatedResults | undefined;
};

const getSavedData = (sessionStorageKey: string): PersistedData | undefined => {
  const savedData = sessionStorage.getItem(sessionStorageKey);
  if (savedData) {
    const persistedData = JSON.parse(savedData) as PersistedData;
    return {
      selectedScore: persistedData.selectedScore,
      comment: persistedData.comment,
      submitted: persistedData.submitted,
      results: persistedData.results,
    };
  }
  return undefined;
};

const ConfidenceVoteParticipant: WSMessageReceiverComponent = (
  { registrationData, sendWS },
  ref,
) => {
  const sessionStorageKey = React.useMemo(
    () =>
      `${registrationData.user_id}@${registrationData.room.room_id}/${
        ConfidenceVoteParticipant.displayName as string
      }`,
    [registrationData],
  );

  const savedData = getSavedData(sessionStorageKey);

  const [selectedScore, setSelectedScore] = React.useState(savedData ? savedData.selectedScore : 0);
  const [comment, setComment] = React.useState(savedData ? savedData.comment : '');

  const [submitted, setSubmitted] = React.useState(savedData ? savedData.submitted : false);

  const [results, setResults] = React.useState<VoteAggregatedResults | undefined>(
    savedData ? savedData.results : undefined,
  );

  React.useImperativeHandle(ref, () => ({
    onMessage: (message) => {
      if (message.nbScores) {
        setResults(message as VoteAggregatedResults);
      }
    },
  }));

  const saveData = React.useCallback(() => {
    sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        selectedScore,
        comment,
        submitted,
        results,
      }),
    );
  }, [sessionStorageKey, selectedScore, comment, submitted, results]);

  React.useEffect(() => {
    saveData();

    return () => {
      saveData();
    };
  }, [saveData]);

  return (
    <VoteParticipant
      toolId="confidence-vote"
      {...{
        selectedScore,
        setSelectedScore,
        comment,
        setComment,
        submitted,
        setSubmitted,
        results,
      }}
      submitCallback={() => {
        sendWS({
          user_id: registrationData.room.owner_id,
          message: { from: registrationData.user_id, score: selectedScore, comment },
        })
          .then(setSubmitted)
          .catch(() => setSubmitted(false));
      }}
    />
  );
};

ConfidenceVoteParticipant.displayName = 'ConfidenceVoteParticipant';

export default withWSMessageReceiver(ConfidenceVoteParticipant);
