import * as React from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import RoomPage from '../../../components/tools/roomPage';
import { VoidPageProps } from '../../../utils/graphql';
import { RegistrationResponse } from '../../../utils/websocketService';
import ConfidenceVoteParticipant from '../../../components/tools/confidence-vote/confidenceVoteParticipant';

const Join: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => {
  const { search, state } = useLocation() as {
    search: string;
    state: { registrationResponse?: RegistrationResponse } | null;
  };
  const { room } = queryString.parse(search) as {
    room?: string;
  };

  return (
    <RoomPage
      pageContext={pageContext}
      toolId="confidence-vote"
      RoomComponent={ConfidenceVoteParticipant}
      registrationResponse={state?.registrationResponse}
      room={room}
    />
  );
};

export default Join;
